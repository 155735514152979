import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Oregon",
  "subtitle": "-",
  "category": "USA"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "portland"
    }}>{`Portland`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ETPbc4cfzAp3yu2Z7"
        }}>{`BAES Fried Chicken`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/BAXfknsPG4pr7LAV8"
        }}>{`Canard (Oregon City)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Original location in `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/6fLwb4LreMfMJ8qB6"
            }}>{`Burnside`}</a>{` also good`}</li>
          <li parentName="ul">{`This one is more spacious and has outdoor seating since it's newer`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Vvw5jG8aWt1r4TaAA"
        }}>{`Hat Yai Belmont`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/TYGihm5hB7MS7ZYU6"
        }}>{`Jojo`}</a>{` - Be ready to wait in line...`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gurcvT5Ex1r7Kvd59"
        }}>{`Pip's Doughnuts`}</a>{` - Line moves fast; Try every flavor! I'm don't like the Chai though.`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/oMmdfgacheVcNfWW8"
        }}>{`Evergreen Aviation & Space Museum`}</a>{` - Spruce Goose tour was nice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/TWz6prV3QQj5trik7"
        }}>{`Tillamook Creamery`}</a>{` - Yes`}</li>
    </ul>
    <h2 {...{
      "id": "cannon-beach"
    }}>{`Cannon Beach`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/JS1AVYvAKRckthFE7"
        }}>{`Haystack Rock`}</a>{` - Amazing sunset views`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/m8CUdMxScXovoGhJ9"
        }}>{`Pelican Brewing`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      